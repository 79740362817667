<template>
  <div class="cont main sc-width ">
    <div v-if="matches" class="mobileBox">
      <div class="box">
        <Form
          ref="create"
          :model="create"
          class="factory-form"
          :label-width="200"
        >
          <FormItem label="LOGO" prop="avatar" :rules="required">
            <Input
              v-model="create.avatar"
              style="position:absolute;left:-9999px"
            />
            <div class="logoWrap">
              <div class="logoUp">
                <p class="logoImg" v-if="create.avatar">
                  <span v-if="imageUploading">
                    loading ...
                  </span>
                  <img alt="" v-else :src="create.avatar" />
                </p>
                <p class="logonull" v-else style="width:100%">
                  <template v-if="imageUploading">
                    loading ...
                  </template>
                  <template v-else>
                    Logo
                  </template>
                </p>
              </div>
              <Button
                v-if="imageUploading"
                :disabled="true"
                icon="md-refresh"
                type="primary"
                ghost
                style="width:100%"
              >
                Uploading ...
              </Button>

              <template v-else>
                <Button
                  v-if="create.avatar"
                  type="primary"
                  icon="md-refresh"
                  ghost
                  @click="$refs.file.click()"
                  style="width:100%"
                >
                  <input
                    v-show="false"
                    type="file"
                    ref="file"
                    @change="handleUpload($event)"
                    accept="image/*"
                  />
                  Replace Logo
                </Button>
                <Button
                  v-else
                  type="primary"
                  ghost
                  @click="$refs.file.click()"
                  style="width:100%"
                >
                  <input
                    v-show="false"
                    type="file"
                    ref="file"
                    @change="handleUpload($event)"
                    accept="image/*"
                  />
                  Select a Logo
                </Button>
                <p
                  class="tip"
                  style="color:#999;font-size:12px;margin-top:-15px"
                >
                  NOTE: Photos should be at least 250 px width by 150 px height.
                </p>
              </template>
            </div>
          </FormItem>

          <FormItem
            label="Name of Factory"
            prop="factory_name"
            :rules="required"
          >
            <Input
              v-model="create.factory_name"
              placeholder="Enter factory name"
            />
          </FormItem>

          <FormItem label="Description" prop="description">
            <!-- <Input
                type="textarea"
                :rows="4"
                v-model="create.description"
              /> -->
            <div class="editWrap" style="height:380px;padding: 35px 0;">
              <quill-editor
                v-model="create.description"
                ref="myQuillEditor"
                style="height:190px;"
              >
              </quill-editor>
            </div>
          </FormItem>
          </FormItem>

          <FormItem label="FDA Number" class="leftHarf">
            <Input v-model="create.fda_number" />
          </FormItem>

          <FormItem label="Phone" class="rightHarf">
            <Input v-model="create.phone" />
          </FormItem>

          <FormItem label="Address">
            <Input v-model="create.address" />
          </FormItem>

          <FormItem label="City" prop="city" :rules="required" class="leftHarf">
            <Input v-model="create.city" />
          </FormItem>

          <FormItem
            label="STATE / PROVINCE"
            prop="state"
            :rules="required"
            class="rightHarf"
          >
            <Input v-model="create.state" />
          </FormItem>

          <FormItem label="COUNTRY" :rules="required" prop="country">
            <Select
              filterable
              v-model="create.country"
              placeholder="Select a country"
            >
              <Option
                v-for="item in countryList"
                :value="item.id"
                :key="item.id"
                >{{ item.name }}</Option
              >
            </Select>
          </FormItem>

          <FormItem
            label="MAIN PRODUCT CATEGORY"
            :rules="required"
            prop="main_product_category"
          >
            <Select
              v-model="create.main_product_category"
              placeholder="select one category"
            >
              <OptionGroup label="Botanical">
                <Option value="Botanical">Botanical</Option>
              </OptionGroup>

              <OptionGroup label="Non-Botanical">
                <Option
                  v-for="item in cateList['Non-Botanical']"
                  :key="item"
                  :value="item"
                  >{{ item }}</Option
                >
              </OptionGroup>
            </Select>
          </FormItem>

          <!-- <FormItem label="Address" prop="address" :rules="required">
              <Input v-model="create.address" />
            </FormItem> -->

          <!-- <FormItem label="Zip Code">
              <Input v-model="create.zip_code" />
            </FormItem> -->

          <FormItem label="CERTIFICATIONS">
            <Select
              filterable
              multiple
              v-model="create.document"
              :max-tag-count="3"
              placeholder="Attach a file"
            >
              <Option
                v-for="item in documentList"
                :value="item.id"
                :key="item.id"
                >{{ item.name }}</Option
              >
            </Select>
          </FormItem>

          <!-- warehouse -->
          <h4 class="jtitle">WAREHOUSE LOCATIONS</h4>
          <div class="composeWrap">
            <div
              class="compose"
              v-for="(item, index) in create.warehouse"
              :key="index"
            >
              <div class="delIt">
                <span>Warehouse {{ index + 1 }}</span>
                <Icon
                  v-if="index !== 0"
                  type="md-trash"
                  @click="removeItem('create', 'warehouse', index)"
                />
              </div>
              <div class="wareContent">
                <Row :gutter="20">
                  <i-col span="8">
                    <FormItem :prop="`warehouse[${index}].city`">
                      <Input v-model="item.city" placeholder="CITY" />
                    </FormItem>
                  </i-col>
                  <i-col span="8">
                    <FormItem :prop="`warehouse[${index}].state`">
                      <Input v-model="item.state" placeholder="STATE" />
                    </FormItem>
                  </i-col>
                  <i-col span="8">
                    <FormItem :prop="`warehouse[${index}].country`">
                      <Select
                        filterable
                        v-model="item.country"
                        placeholder="Select a country"
                      >
                        <Option
                          v-for="item in countryList"
                          :value="item.id"
                          :key="item.id"
                          >{{ item.name }}</Option
                        >
                      </Select>
                    </FormItem>
                  </i-col>
                </Row>
              </div>
            </div>
            <p class="addHouse">
              <span
                @click="
                  addItem('create', 'warehouse', {
                    city: null,
                    state: null,
                    country: null
                  })
                "
                >+ Add a warehouse</span
              >
            </p>
          </div>

          <FormItem style="text-align:center">
            <Button
              :loading="createLoading"
              type="primary"
              @click="createSubmit"
              style="width:100%;margin:30px auto;"
              >Add Factory</Button
            >
          </FormItem>

        </Form>
      </div>
    </div>
    <div v-else>
      <div class="box">
        <div class="title">Add Webinar</div>
        <Form ref="create" :model="create" class="factory-form">
          <FormItem label="Avatar" prop="avatar" :rules="required">
            <Input
              v-model="create.avatar"
              style="position:absolute;left:-9999px"
            />
            <div class="logoWrap">
              <div class="logoUp">
                <p class="logoImg" v-if="create.avatar">
                  <span v-if="imageUploading">
                    loading ...
                  </span>
                  <img alt="" v-else :src="create.avatar" />
                </p>
                <p class="logonull" v-else>
                  <template v-if="imageUploading">
                    loading ...
                  </template>
                  <template v-else>
                    Avatar
                  </template>
                </p>
              </div>
              <Button
                v-if="imageUploading"
                :disabled="true"
                icon="md-refresh"
                type="primary"
                ghost
              >
                Uploading ...
              </Button>
              <template v-else>
                <Button
                  v-if="create.avatar"
                  type="primary"
                  icon="md-refresh"
                  ghost
                  @click="$refs.file.click()"
                >
                  <input
                    v-show="false"
                    type="file"
                    ref="file"
                    @change="handleUpload($event)"
                    accept="image/*"
                  />
                  Replace Avatar
                </Button>
                <Button v-else type="primary" ghost @click="$refs.file.click()">
                  <input
                    v-show="false"
                    type="file"
                    ref="file"
                    @change="handleUpload($event)"
                    accept="image/*"
                  />
                  Select a Avatar
                </Button>
                <p
                  class="tip"
                  style="color:#999;font-size:12px;margin-top:-15px"
                >
                  NOTE: Photos should be at least 250 px width by 150 px height.
                </p>
              </template>
            </div>
          </FormItem>

          <FormItem
            label="Title of Webinar"
            prop="Title"
          >
            <Input
              v-model="create.title"
              placeholder="Enter Title"
            />
          </FormItem>

          <FormItem label="Description" prop="description">
            <div class="editWrap" style="">
              <Input
                type="textarea"
                v-model="create.desc"
                placeholder="Enter Description"
              />
            </div>
          </FormItem>

          <FormItem label="Date" class="rightHarf">
            <Input v-model="create.date" />
          </FormItem>

          <FormItem style="text-align:center">
            <Button
              :loading="createLoading"
              type="primary"
              @click="createSubmit"
              style="width:200px;margin:30px auto;"
              >Add Webinar</Button
            >
            <Button
              type="warning"
              @click="back('factory')"
              style="width:200px;margin:30px 20px;color: #f57f45;background: #fff"
            >Cancel</Button
            >
          </FormItem>
        </Form>
      </div>
      <div  class="form-foot"></div>
    </div>
    <!--    </MatchMedia>-->
  </div>
</template>

<script>
import api from "@/api";
// import { MatchMedia } from "vue-component-media-queries";
const {
  _createWebinar,
} = api;

import S3 from "aws-s3";

const uploader = new S3({
  bucketName: "connexima",
  dirName: "documents",
  region: "us-west-1",
  accessKeyId: "AKIA6KR6UZLLVZYGDEZM",
  secretAccessKey: "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"
});

export default {
  components: {
    // MatchMedia
  },
  created() {

  },
  data() {
    return {
      loading: false,
      imageUploading: false,
      imageFile: null,
      videoUploading: false,
      videoFile: null,
      required: [
        {
          required: true,
          message: "Please fill"
        }
      ],

      documentList: [],
      countryList: [],
      cateList: [],
      createLoading: false,
      create: {
        title: null,
        desc: null,
        address: null,
        date: null,
      },
      matches: false
    };
  },

  methods: {
    back(type) {
      this.$router.push({ path: "/company-profile", query: { type: type } });
    },
    createSubmit() {
      this.$refs.create.validate(valid => {
        if (valid) {
          this.createLoading = true;
          var createCopy = JSON.parse(JSON.stringify(this.create));
          _createWebinar({ ...createCopy })
            .then(() => {
              this.$Message.success("Add Webinar success");
              setTimeout(() => {
                this.$router.push("/company-profile/webinars");
              }, 500);
            })
            .catch(({ message }) => {
              this.$Message.error(message);
            })
            .finally(() => {
              this.createLoading = false;
            });
        }
      });
    },
    handleUpload(e, type) {
      const file = e.target.files[0];
      if (file) {
        if (file.size > 20 * 1024 * 1024) {
          this.$Message.error("File size cannot exceed 20MB");
        } else {
          if (type === "video") {
            this.videoFile = file;
          } else {
            this.imageFile = file;
          }
          this.upload(type);
        }
      }
    },

    upload(type) {
      let file;
      if (type === "video") {
        this.videoUploading = true;
        file = this.videoFile;
      } else {
        this.imageUploading = true;
        file = this.imageFile;
      }

      uploader
        .uploadFile(file)
        .then(data => {
          if (type === "video") {
            this.create.video = data.location;
          } else {
            // this.create.avatar = data.location;
            this.$set(this.create, "avatar", data.location);
          }
          console.log(data.location);
        })
        .catch(() => {
          this.$Message.error("upload error");
        })
        .finally(() => {
          if (type === "video") {
            this.videoUploading = false;
          } else {
            this.imageUploading = false;
          }
        });
    },
    addItem(form, name, obj) {
      this[form][name].push(obj);
    },
    removeItem(form, name, index) {
      this[form][name].splice(index, 1);
    }
  }
};
</script>

<style lang="less" scoped>
.back {
  color: @primary-color;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 14px 24px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  background: #fff;
}
.cont {
  /*background: #fff;*/
  margin: 70px auto 0 auto;

  .box {
    margin: 75px 0 10px 0;
    /*width: 800px;*/
    /*padding: 20px 30px;*/
    /*box-shadow: 0 2px 16px grey, 0 0 1px grey, 0 0 1px grey;*/
    background: #fff;
    border-radius: 15px;
    position: relative;
    top: 30px;
    .factory-form {
      width: 1130px;
      margin: 30px auto 0 auto;
    }
    .title {
      height: 60px;
      background: #fff;
      /*opacity: 0.9;*/
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      font-size: 20px;

      font-weight: 700;
      color: #292929;
      line-height: 18px;
      padding-top: 20px;
      display: flex;
      /*flex-direction: column;*/
      justify-content: center;
    }
    .upload {
      width: 100%;
      display: inline-block;
      .upload-success {
        display: flex;
        align-items: center;
        .text {
          margin-left: 4px;
        }
      }
    }
    .logoWrap {
      .logoUp {
        .logoImg {
          height: 120px;
          margin-top: 36px;
          font-size: 12px;
          line-height: 120px;
          img {
            height: 100%;
            border: 1px solid #ddd;
          }
        }
        .logonull {
          font-size: 12px;
          text-align: center;
          line-height: 120px;
          margin-top: 36px;
          width: 120px;
          height: 120px;
          border: 1px dashed #ddd;
        }
      }
      .ivu-btn {
        margin: 20px 0;
      }
    }
  }

  .mobileBox {
    .box {
      width: 100%;
      margin: 10px 0;
      padding: 10px;
    }
  }
}

.leftHarf {
  display: inline-block;
  width: 49%;
  margin-right: 1%;
}
.rightHarf {
  display: inline-block;
  width: 49%;
  margin-left: 1%;
}
.ivu-form-item {
  margin-bottom: 20px;
}
.jtitle {
  width: 100%;
  font-size: 14px;
  color: #515a6e;
  font-weight: normal;
  padding: 10px 12px 10px 0;
}
.composeWrap {
  .compose {
    margin-bottom: 20px;
    .delIt {
      border: 1px solid #ddd;
      padding: 6px 10px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      span {
        color: #666;
      }
      .ivu-icon {
        font-size: 22px;
        color: red;
        float: right;
        cursor: pointer;
      }
    }
    .wareContent {
      border: 1px solid #ddd;
      padding: 5px 10px;
      padding-top: 15px;
      border-top: none;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      .ivu-form-item {
        margin-bottom: 15px;
      }
    }
  }
}

.addHouse {
  color: @primary-color;
  text-align: right;
  span {
    cursor: pointer;
  }
}
.top {
  .back-request {
    width: 220px;
    height: 40px;
    /*background: #ef7f21;*/
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    margin-top: 50px;
    margin-bottom: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    top: 39px;
    align-items: center;
    .pic {
      width: 8px;
      height: 10px;
      margin-right: 10px;
      margin-top: 2px;
      img {
        width: 20px;
        height: 20px;
        position: relative;
        top: 8px;
        left: 0px;
      }
    }

    .word {
      position: relative;
      /*top: 9px;*/
      font-size: 20px;

      font-weight: 500;
      color: #ef7f21;
      line-height: 18px;
    }
  }
}
  .form-foot{
    height: 30px;
  }
</style>
<style scoped>
.form-box >>> .ivu-card-bordered {
  border: none;
}

.factory-form >>> .ivu-form-item-label {
  font-size: 16px;

  font-weight: 400;
  color: #292929;
  line-height: 18px;
  width: 200px;
}
.factory-form >>> .ivu-form-item-content {
  margin-left: 200px;
}
.ivu-btn-primary {
  width: 200px;
  height: 34px;
  background: linear-gradient(90deg, #f5a210, #ef8126);
  border-radius: 5px;
  font-size: 18px;

  font-weight: 400;
  color: #ffffff;
  line-height: 18px;
}
.ivu-steps {
  width: 1150px;
  margin: 0 auto;
  justify-content: space-between;
}
/*.ivu-form-item{*/
/*  width: 1200px;*/
/*}*/
</style>
